@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

.form-control.error,
.contest-input.error {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.6rem #ff0000;
  border: solid 1px #ff0000;
}

.form-control[type="file"] {
  padding-top: 13px;
}

.contest-input {
  width: 100px;
  border: none;
  text-align: center;
  outline: none;
  margin-right: 10px;
  border-radius: 25px;
}

.pointer {
  cursor: pointer;
}

.imp-note ul li {
  background: url("/public/images/bullete-circle.png");
  font-size: 13px;
  margin-bottom: 15px;
  line-height: normal;
  marker-start: inherit;
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: 0px 3px;
}

.sportimg img {
  max-height: 161px;
  max-width: 161px;
}

.alert-danger ul li {
  list-style: disc;
}

.mt45 {
  margin-top: 45px !important;
}

.b1 {
  border: 1px solid #cfcfcf;
}

.pt-30 {
  padding-top: 30px;
}

.upload-profile {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.app-loadering {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea.form-control {
  height: 132px;
}

.form-check-input.error {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.6rem #ff0000;
  border: solid 1px #ff0000;
}

.error-text {
  animation: shake 0.2s ease-in-out 0s 2;
  color: #ff0000;
}

.team-image {
  width: 190px;
  height: 190px;
}

.img-mx-height {
  max-height: 56px;
}

.img100 {
  width: 100px;
  height: 100px;
}

.dropdown-menu-center {
  right: auto !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0) !important;
  border: 0px;
  /* inset: 22px auto auto 0px !important; */
}

.lang-dropdown li {
  display: block !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.min-height {
  min-height: calc(100vh - 470px);
}

input[type="number"] {
  -moz-appearance: "textfield";
}

.lang-dropdown .dropdown-menu::after {
  position: absolute;
  top: -16px;
  left: 50%;
  display: inline-block;
  border-right: 10px solid transparent;
  border-bottom: 16px solid white;
  border-left: 10px solid transparent;
  content: "";
  margin-left: -14px;
}

.win-capsule {
  border: solid 2px #12bf1a;
  background: #fff;
  padding: 7px 12px;
  font-size: 16px;
  color: #12bf1a;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
}

.lost-capsule {
  border: solid 2px #f00;
  background: #fff;
  padding: 10px 12px;
  font-size: 16px;
  color: #f00;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
}

.fx-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.react-tooltip {
  z-index: 999;
}

.aritile-gridview .articl-icon img {
  width: 85px !important;
}

.ariclebox img {
  max-width: 200px !important;
}

.filter-box-ul {
  box-shadow: none !important;
  padding: 0 !important;
}

.filters ul li .dropdown-toggle img {
  margin-right: 5px !important;
}

.orange-text {
  color: #f0955b !important;
}

.home-sports-box-white {
  min-height: 289px !important;
}

.article-title {
  margin: 0px 0px 15px !important;
}

.tabviewbar {
  margin-top: 0px !important;
}

.mt60 {
  margin-top: 60px;
}

.ariclebox {
  height: 100%;
}

.photos-credit {
  margin: auto;
  width: 100%;
  position: absolute;
  bottom: -26px;
  left: 0;
}

.photos-credit .photos-buy {
  position: unset;
}

.ariclebox-home {
  height: auto !important;
}
.achievement-image {
  height: 200px !important;
}
.vimeo-video {
  height: 100% !important;
}
.profilepagetab .resp-tabs-list li {
  width: 200px !important;
}
@media (max-width: 456px) {
  .btn-orange {
    padding-left: 25px !important;
  }
}

@media (max-width: 436px) {
  .btn-orange {
    padding-left: 20px !important;
  }
}
@media (max-width: 436px) {
  .btn-orange {
    padding-left: 50px;
  }
  .btn-orange.deleteicon {
    padding-left: 10px !important;
  }
  .btn-orange.cart-icon {
    padding-left: 20px !important;
  }
  .btn-orange.buy-media-button {
    padding-left: 20px !important;
  }
  .btn-orange.contest-submit{
    padding-left: 15px !important;
  }
}
